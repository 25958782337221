.Carriers {
  width: 100%;
  max-width: 800px;
}

.Carriers h1 {
  text-align: center;
  margin-bottom: 50px;
}

.Carriers .CarrierButtons {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 190px);
  /* justify-content: center; */

  margin-top: 40px;
  margin-bottom: 65px;
}

.StickyAction {
  box-shadow: 0px -2px 9px 1px rgba(123, 123, 123, 0.15);
  background-color: white;
  padding: 25px 0;

  position: fixed;
  bottom: 0;

  text-align: center;
}