body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Signup_Signup__2WxH2 {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 1150px) {
  .Signup_Signup__2WxH2 {
    grid-template-columns: minmax(320px, 792px) 1fr;
    grid-template-rows: min-content 1fr;
  }

  .Signup_Signup__2WxH2 > div:nth-of-type(2) {
    order: 3;
  }

  .Signup_Signup__2WxH2 > div:nth-of-type(3) {
    order: 2;
  }
}

.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 70%;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 15%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.Header_Header__34Yn1 {
  background-color: #34b558;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.Header_Header__34Yn1 img {
  max-height: 46px;
}

.Header_Header__34Yn1 .Header_Menu__1jPhh {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}

.Header_Header__34Yn1 .Header_Menu__1jPhh div + div {
  margin-left: 20px;
}

.Header_Header__34Yn1 .Header_Black__2zBKQ {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.Header_Header__34Yn1 .Header_White__2sEEm {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: white;
}

.Header_Header__34Yn1 .Header_White__2sEEm a,
.Header_Header__34Yn1 .Header_White__2sEEm a:active {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: white;
  text-decoration: none;
}

@media (min-width: 700px) {
  .Header_Header__34Yn1 {
    padding: 38px 50px;
    flex-direction: row;
    align-items: stretch;
  }
}

@media (min-width: 1150px) {
  .Header_Header__34Yn1 {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 1450px) {
  .Header_Header__34Yn1 {
    flex-direction: row;
    align-items: stretch;
  }
}

.LatesInfo_LatesInfo__2BV1h {
  background-color: #34b558;
  background: linear-gradient(to bottom, #34b558 0%,#34b57a 100%);
  padding: 25px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.LatesInfo_LatesInfo__2BV1h .LatesInfo_Illustration__2IhzZ {
  width: 320px;
  height: 302px;
  max-width: 100%;
}

.LatesInfo_LatesInfo__2BV1h .LatesInfo_Info__24v-P {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18.67px;
  color: white;
  line-height: 37.3px;
  margin-bottom: 75px;
}

@media (min-width: 700px) {
  .LatesInfo_LatesInfo__2BV1h {
    padding: 38px 50px;
  }
}

.ReviewCard_ReviewCard__1FAg1 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;
  background-color: #c2ffdb;
  padding: 15px 25px;
  border-radius: 20px;
}

.ReviewCard_ReviewCard__1FAg1 img {
  width: 40px;
  height: 40px;
}

.ReviewCard_ReviewCard__1FAg1 .ReviewCard_date__1ZzrP {
  color: #939393;
}

.ReviewCaroussel_ReviewCaroussel__3WSYK {
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 80px;
}

.ReviewCaroussel_ReviewCaroussel__3WSYK .ReviewCaroussel_buttons__3D3PN {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.ReviewCaroussel_ReviewCaroussel__3WSYK .ReviewCaroussel_buttons__3D3PN > div {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  background-color: #00ad54;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.ReviewCaroussel_ReviewCaroussel__3WSYK .ReviewCaroussel_buttons__3D3PN > div:nth-child(1) {
  margin-right: 10px;
}
.ReviewCaroussel_ReviewCaroussel__3WSYK .ReviewCaroussel_buttons__3D3PN > div > div {
  position: relative;
  top: 1px;
}

.ReviewCaroussel_ReviewCaroussel__3WSYK > div + div {
  border-top: 1px solid #64e3a1;
}

.ReviewCaroussel_ReviewCaroussel__3WSYK .ReviewCaroussel_fadeIn__2gbsF {
  animation-name: ReviewCaroussel_fadeIn__2gbsF;
  animation-duration: 1s;
}

@keyframes ReviewCaroussel_fadeIn__2gbsF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ReviewCaroussel_ReviewCaroussel__3WSYK .ReviewCaroussel_fadeOut__1Ppej {
  animation-name: ReviewCaroussel_fadeOut__1Ppej;
  animation-duration: 1s;
}

@keyframes ReviewCaroussel_fadeOut__1Ppej {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.Faq_Faq__27HIo {
 text-align: left;
 margin: 0 20px;
}

.Faq_Faq__27HIo h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 18.67px;
  color: #404041;
}

.Faq_Faq__27HIo > div + div {
  border-top: 1px solid #64e3a1;
}

.Qa_Qa__11Kq7 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;

  cursor: pointer;
}

.Qa_Qa__11Kq7 .Qa_Question__jH_qq {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.Qa_Qa__11Kq7 .Qa_Question__jH_qq img {
  width: 25px;
  height: 25px;
}

.Qa_Qa__11Kq7 img.Qa_Rotate__3QiWw {
  transform: rotate(90deg);
}

.Qa_Qa__11Kq7 .Qa_Answer__3zSkM {
  margin-bottom: 15px;
}

.Qa_Qa__11Kq7 .Qa_Hidden__2KfZJ {
  display: none;
}

.ClubInfo_ClubInfo__3irer {
  background-color: #34b558;
  background: linear-gradient(to bottom, #34b558 0%, #34b57a 100%);
  padding: 25px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.ClubInfo_ClubInfo__3irer .ClubInfo_Logo__kpfXf {
  font-size: 24px;
}

.ClubInfo_ClubInfo__3irer .ClubInfo_Logo__kpfXf img {
  width: 250px;
  height: 250px;
  max-width: 100%;
  font-size: 24px;
}

.ClubInfo_ClubInfo__3irer .ClubInfo_Info__2vksT {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18.67px;
  color: white;
  line-height: 37.3px;
  margin-bottom: 75px;
}

@media (min-width: 700px) {
  .ClubInfo_ClubInfo__3irer {
    padding: 38px 50px;
  }
}

.ProgressBar_ProgressBar__1a3lp {
  display: none;
}

@media (min-width: 700px) {
  .ProgressBar_ProgressBar__1a3lp {
    padding: 38px 50px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .ProgressBar_ProgressBar__1a3lp > div + div {
    margin-left: 44px;
  }

  .ProgressBar_ProgressBar__1a3lp div {
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    font-size: 21.3px;
    text-transform: uppercase;
    color: #404041;
    height: 32px;
    border-bottom: 5px solid #ededed;
  }

  .ProgressBar_ProgressBar__1a3lp div.ProgressBar_Active__2KEWs {
    border-bottom-color: #34b558;
  }
}

.Page_Page__1IEZ6 {
  padding: 25px;
  display: flex;
  justify-content: center;
  position: relative;
}

.Page_Page__1IEZ6 h1 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  font-size: 25px;
  text-transform: uppercase;
  color: #404041;
  margin-top: 0;
  margin-bottom: 6px;
}

.Page_Page__1IEZ6 h2 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #404041;
  margin-bottom: 6px;
}

.Page_Page__1IEZ6 p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;
  margin-top: 0;
  margin-bottom: 40px;
}

.Page_Page__1IEZ6 hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #cdcdcd;
  margin: 45px 0;
  padding: 0;
}

@media (min-width: 700px) {
  .Page_Page__1IEZ6 {
    padding: 38px 50px;
  }

  .Page_Page__1IEZ6 h1 {
    font-size: 40px;
  }
}

@media (min-width: 1150px) {
  .Page_Page__1IEZ6 {
    min-width: 650px;
  }
}

.Account_Account__1UYMo {
  width: 100%;
  max-width: 680px;
  text-align: center;
}

.Account_Account__1UYMo .Account_Forms__2Z6up {
  max-width: 600px;
  margin: auto;
}

.Account_Account__1UYMo .Account_Next__1qvfj {
  margin-top: 40px;
  margin-bottom: 50px;
}

.Form_Form__1ZYD3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
}

.Form_Form__1ZYD3 h2 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #404041;
  margin-bottom: 6px;
}

.Form_Form__1ZYD3 p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #404041;
  margin-bottom: 15px;
}

.Input_Input__RPIE0 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;
  position: relative;
  width: 100%;
  background: transparent;
}

.Input_Input__RPIE0 input {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  width: 100%;
  margin: 25px 0;
  border: none;
  border-bottom: 3px solid #34b558;
  outline: none;
  padding: 5px 0;
  color: #404041;
  background: transparent;
}

.Input_Input__RPIE0 input[type='checkbox'],
.Input_Input__RPIE0 input[type='radio'] {
  width: auto;
  margin: 25px 10px 25px 0;
  border: none;
  border-bottom: 3px solid #34b558;
  outline: none;
  padding: 5px 0;
  color: #404041;
  background: transparent;
}

.Input_Input__RPIE0.Input_MovingPlaceholder__1YnN8 span {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 30px;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.Input_Input__RPIE0.Input_MovingPlaceholder__1YnN8.Input_HasFocus__3oS91 span {
  top: 6px;
  font-size: 14.67px;
  color: #34b558;
}

.Input_Input__RPIE0.Input_Error__2R0DH input {
  border-bottom: 3px solid red;
}

.Input_Input__RPIE0.Input_Error__2R0DH span {
  color: red;
}

.Input_Input__RPIE0 label {
  cursor: pointer;
}

.Input_Input__RPIE0 input[type="radio"] {
  transform: scale(1.3); /* Increases size of the radio button */
  margin-right: 8px;
  cursor: pointer;
}

@media (min-width: 480px) {
  .Input_Input__RPIE0.Input_Half__3MLVE {
    width: calc(50% - 22px);
  }
}

.Button_Button__2gyoG {
  background-color: #34b558;
  background: linear-gradient(to right, #34b558 0%, #34b57a 75%);
  border: none;
  border-radius: 25px;
  outline: none;
  height: 50px;
  padding: 0 25px;
  cursor: pointer;

  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #363032;
}

.Button_Button__2gyoG:hover {
  color: white;
}

.ErrorMessage_ErrorMessage__3m1V8 {
  background-color: #f2ac31;
  display: flex;
  align-items: center;
  border-radius: 6.67px;
  padding: 25px 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: white;
  width: 100%;
}

.ErrorMessage_ErrorMessage__3m1V8 img {
  display: block;
  width: 44px;
  height: 45px;
  margin-right: 25px;
}

.ErrorMessage_ErrorMessage__3m1V8 div {
  width: auto !important;
}

.Carriers_Carriers__ay3P1 {
  width: 100%;
  max-width: 800px;
}

.Carriers_Carriers__ay3P1 h1 {
  text-align: center;
  margin-bottom: 50px;
}

.Carriers_Carriers__ay3P1 .Carriers_CarrierButtons__ieYZr {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 190px);
  /* justify-content: center; */

  margin-top: 40px;
  margin-bottom: 65px;
}

.Carriers_StickyAction__3dtQd {
  box-shadow: 0px -2px 9px 1px rgba(123, 123, 123, 0.15);
  background-color: white;
  padding: 25px 0;

  position: fixed;
  bottom: 0;

  text-align: center;
}
.CarrierButton_CarrierButton__2f76k {
  width: 100%;
  height: 142px;
  background-color: #f4f7f9;
  border-radius: 13.33px;
  border: none;
  outline: none;
  padding: 40px 15px;
  cursor: pointer;
  position: relative;
  margin-bottom: 12.5px;
}

.CarrierButton_CarrierButton__2f76k img.CarrierButton_Logo__2idbv {
  max-width: 100%;
  max-height: 100%;
}

.CarrierButton_CarrierButton__2f76k .CarrierButton_AddButtonInactive__3Rbsq {
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: -12.5px;
  left: calc(50% - 12.5px);
}

.CarrierButton_CarrierButton__2f76k:hover .CarrierButton_AddButtonInactive__3Rbsq {
  display: none;
}

.CarrierButton_CarrierButton__2f76k .CarrierButton_AddButton__3ZgHB {
  display: none;
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: -12.5px;
  left: calc(50% - 12.5px);
}

.CarrierButton_CarrierButton__2f76k:hover .CarrierButton_AddButton__3ZgHB {
  display: block;
}

.CarrierBox_CarrierBox__1oLb3 {
  margin-top: 20px;
  margin-bottom: 45px;
}

.CarrierBox_CarrierBox__1oLb3 .CarrierBox_Title__3Rdxl {
  background-color: #404041;
  border-radius: 13.33px 13.33px 0 0;
  padding: 22px 25px;
  display: flex;
  justify-content: space-between;

  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: white;
}

.CarrierBox_CarrierBox__1oLb3 .CarrierBox_Title__3Rdxl button {
  background: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.CarrierBox_CarrierBox__1oLb3 .CarrierBox_Title__3Rdxl button img {
  width: 16px;
  height: 16px;
}

.CarrierBox_BottomWorking__1briR {
  background-color: #737373;
  display: flex;
  align-items: center;
  border-radius: 0 0 6.67px 6.67px;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.CarrierBox_BottomWorking__1briR img {
  display: block;
  width: 44px;
  height: 45px;
  margin-right: 25px;
}

.CarrierBox_BottomDone__2vo1z {
  background-color: #30a95c;
  display: flex;
  align-items: center;
  border-radius: 0 0 6.67px 6.67px;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.CarrierBox_BottomDone__2vo1z img {
  display: block;
  width: 27px;
  height: 27px;
  margin-right: 15px;
}

@media (min-width: 700px) {
  .CarrierBox_BottomWorking__1briR,
  .CarrierBox_BottomDone__2vo1z {
    padding: 15px 60px;
  }
}

.ConnectStep_ConnectStep__3rkt7 {
  background-color: #f4f7f9;
  padding: 25px;
}

.ConnectStep_ConnectStep__3rkt7 p {
  font-size: 20px;
  line-height: 24px;
  color: #404041;
  width: 100%;
}

.ConnectStep_ConnectStep__3rkt7 div {
  width: 100%;
}

.ConnectStep_ConnectStep__3rkt7 .ConnectStep_textDanger__1wjt2 {
  color: red;
}

.ConnectStep_ConnectStep__3rkt7 .ConnectStep_small__149QJ {
  font-size: 14px;
}

.ConnectStep_ConnectStep__3rkt7 .ConnectStep_hidden__1xix6 {
  display: none;
}

.ConnectStep_ConnectStep__3rkt7 .ConnectStep_Button__4wTMw {
  display: flex;
  justify-content: flex-end;
}

.ConnectStep_ConnectStep__3rkt7 .ConnectStep_inputError__2wR9G input {
  border-bottom: 3px solid red; 
  color: red; 
}

.ConnectStep_ConnectStep__3rkt7 .ConnectStep_inputError__2wR9G span,
.ConnectStep_ConnectStep__3rkt7 .ConnectStep_inputError__2wR9G input {
  color: red !important;
}


@media (min-width: 700px) {
  .ConnectStep_ConnectStep__3rkt7 {
    padding: 45px 60px;
  }
}

.Confirmation_Confirmation__2w2Tp {
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.Confirmation_Confirmation__2w2Tp p {
  margin-bottom: 5px;
}

.Confirmation_Confirmation__2w2Tp .Confirmation_Next__1Ckxc {
  margin-top: 40px;
  margin-bottom: 50px;
}

.HubspotForm_Account__2OZ8p {
  width: 100%;
  max-width: 680px;
  text-align: center;
}

.HubspotForm_Account__2OZ8p .HubspotForm_Forms__3jH4E {
  max-width: 600px;
  margin: auto;
}

.HubspotForm_Account__2OZ8p .HubspotForm_Next__3kclU {
  margin-top: 40px;
  margin-bottom: 50px;
}

.HubspotForm_Account__2OZ8p input.HubspotForm_primary__2N5_c {
  background-color: #34b558;
  background: linear-gradient(to right, #34b558 0%, #34b57a 75%);
  border: none;
  border-radius: 25px;
  outline: none;
  height: 50px;
  padding: 0 25px;
  cursor: pointer;

  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #363032;
}

.HubspotForm_Account__2OZ8p input.HubspotForm_primary__2N5_c {
  color: white;
}

.Loader_Loader__1kDru circle {
  fill: #34b55f;
}

